<template>
    <div style="width: 100%;height:auto;">
        <div style="width: 100%;background: #FFFFFF;">
            <div style="width: 1500px;height: 80px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
                <div style="display: flex;align-items: center;">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="height: 46px;width: auto;margin: 0 10px;"/>
                    <div style="color: #031F88;font-size: 30px;font-weight: bold"> | 红包问答</div>

                    <div style="width: 600px;background: #FFFFFF"></div>
                </div>
                <div style="display: flex;align-items: center;">
                    <AvatarMenu></AvatarMenu>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 160px;background:url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/questionsandanswers.png')">
            <div style="display: flex;justify-content: center;padding-top: 34px;">
                <div style="margin: 0px auto;">
                    <div style="width: 100%;display: flex;color: #666666;text-align: center;">
                        <div v-for="(item,index) in QatypeList" :key="index" >
                            <div style="width:116px;height: 37px;display: flex;justify-content: center;align-items:center;font-size: 16px;cursor: pointer;" @click="add_type(item.type)" :class="{ Fcti: item.type === typeId }">{{item.name}}</div>
                        </div>
                    </div>
                    <div style="width: 880px;position: relative;">
                        <el-input v-model="like" prefix-icon="el-icon-search" placeholder="请输入你想要查询的内容以获得帮助......" style="height: 40px;width: 100%;font-size: 16px;border-radius:0px;border: 2px solid #FF5E5E;">
                            <template slot="append"><el-button type="primary"  @click="qaSearch" style="background: #FF5E5E;color: #FFFFFF;border-radius:0px;height: 41px;margin: -10px -21px;">搜索</el-button></template>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1500px;margin: 0 auto;padding-top: 10px;">
            <div style="width: 100%;height: 183px;background: #FFFFFF;">
                <div style="display: flex;">
                    <div class="hidden">一级类目</div>
                    <div v-for="(label,index) in type_list" :key="index" style="margin-top: 20px;">
                        <div @click="add_type_list(label.value)" :style="Typelist == index?'color:#FF5E5E':'color:#666666'" class="op sc hand" style="font-size: 18px;float: left;width: 36px;margin-left: 40px;cursor: pointer;font-weight: bold">{{label.label}}</div>
                    </div>
                </div>
                <div style="border-bottom: 1px solid #DCDCDC;padding: 10px 0px;width: 100%;margin: 0 auto"></div>
                <div style="display: flex;">
                    <div class="hidden">问答分类</div>
                    <div v-for="(name,index) in QatList" :key="index"  style="margin-top: 20px;">
                        <div @click="classification(name.type)" class="op sc hand" :style="rewardType == index?'color:#FF5E5E':'color:#666666'"  style="font-size: 18px;float: left;margin-left: 40px;cursor: pointer;font-weight: bold">{{name.name}}</div>
                    </div>
                </div>
                <div style="border-bottom: 1px solid #DCDCDC;padding: 10px 0px;width: 100%;margin: 0 auto"></div>
                <div style="display: flex;padding-bottom: 5px;">
                    <div class="hidden">问题状态</div>
                    <div  v-for="(value,index) in paid" :key="index" style="margin-top: 20px;">
                        <div @click="addisReward(value.isRewardId)" class="op sc hand" :style="isReward == index?'color:#FF5E5E':'color:#666666'" style="font-size: 18px;color:#666666;float: left;margin-left: 40px;cursor: pointer;font-weight: bold">{{value.isReward}}</div>
                    </div>
                </div>
            </div>
            <div style="width: 100%;min-height: 780px;background: #FFFFFF;margin-top: 10px;">
                <el-table
                    :data="searchList"
                    style="width: 100%;margin-top: 5px;height: 800px;">
                    <el-table-column width="130"  class="no-header">
                        <template slot-scope="scope">
                            <div style="width: 100%;overflow: hidden;margin-left: 20px">
                                <el-tag effect="plain" v-if="scope.row.rewardType == 1" size="small" style="color: #fff;background-color: #f56c6c;border-color: #f56c6c;height: 25px;line-height: 23px;font-size: 15px;font-weight: bold">红包问答</el-tag>
                                <el-tag effect="plain" v-if="scope.row.rewardType == 2" size="small" style="color: #fff;background-color: #e6a23c;border-color: #e6a23c;height: 25px;line-height: 23px;font-size: 15px;font-weight: bold">悬赏资料</el-tag>
                                <el-tag effect="plain" v-if="scope.row.rewardType == 3" size="small" style="color: #fff;background-color: #67c23a;border-color: #67c23a;height: 25px;line-height: 23px;font-size: 15px;font-weight: bold">悬赏讨论</el-tag>
                                <el-tag effect="plain" v-if="scope.row.rewardType == 4" size="small" style="color: #fff;background-color: #409eff;border-color: #409eff;height: 30px;line-height: 27px;font-size: 15px;font-weight: bold">必选竞标</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="1070">
                        <template slot-scope="scope"  class="no-header">
                            <div style="display: flex">
                                <div @click="b('/index/page/?id='+scope.row.id)" style="font-size: 18px;float: left;cursor: pointer;font-weight: 600;max-width: 864px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{scope.row.title}}</div>
                                <div v-if="scope.row.rewardMoney > 0"><img src="../../assets/imgs/index/release.png" style="width: 16px;height: 18px;margin-top: 3px;margin-left: 10px;"></div>
                                <div v-if="scope.row.rewardMoney > 0" style="color:#FF5E5E;font-size: 18px;margin-left:5px;font-weight: 600;">{{scope.row.rewardMoney}}元</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100"  class="no-header">
                        <template slot-scope="scope">
                            <span style="font-size: 18px;color: #999999;">{{scope.row.reviewCount}}回答</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="200"  class="no-header">
                        <template slot-scope="scope">
                            <span style="font-size: 18px;color: #999999;">{{scope.row.updateTime | parseTime('{y}-{m}-{d}')}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="searchCount > 0" style="display: flex;justify-content: center;margin-top: 10px;">
                    <div style="margin: 0px auto">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :page-size="6"
                            :total="searchCount"
                            @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import AvatarMenu from "../../components/common/AvatarMenu";
import {formatTime, parseTime} from '@/utils/utils'
export default {
    components: {
        AvatarMenu
    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    filters: {
        parseTime, formatTime
    },
    data () {
        return {
           QatypeList:[
               {name:'红包问答',type:1},
               {name:'悬赏资料',type:2},
               {name:'悬赏讨论',type:3},
               {name:'比选竞标',type:4},
           ],
           typeId:1,
           like:'',
           type_list:[
               {label:'全部',value:0},
               {label:'策划',value:1},
               {label:'规划',value:2},
               {label:'设计',value:3},
               {label:'建设',value:4},
               {label:'运营',value:5},
               {label:'管理',value:6},
           ],
            QatList:[
                {name:'全部',type:0},
                {name:'红包问答',type:1},
                {name:'悬赏资料',type:2},
                {name:'悬赏讨论',type:3},
                {name:'比选竞标',type:4},
            ],
            page: 1,
            pageSize:14,
            searchList:[],
            searchCount:'',
            Typelist:0,
            rewardType:0,
            paid:[
                {isReward:'全部',isRewardId:0},
                {isReward:'有偿问题',isRewardId:1},
            ],
            isReward:0,

        }
    },
    mounted:function(){
        this.qaSearch()
    },
    methods:{
        add_type(typeId){
            this.typeId = typeId;
        },
        // 分页
        handleCurrentChange(val) {
            this.page = val;
            this.qaSearch();
        },
        qaSearch:function(){
            var that = this;
            var param = {};
            param.type = 1;
            param.rewardType = that.rewardType;
            param.like = that.like;
            param.isReward = that.isReward;
            param.page = that.page;
            param.pageSize = that.pageSize;
            that.newApi.qaSearch(param).then((res)=>{
                if (res.isSuccess == 1){
                    that.searchList = res.data.list;
                    that.searchCount = res.data.count;
                    that.like = ""
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        add_type_list(value){
            this.Typelist = value;
            this.qaSearch();
        },
        classification(type){
            this.rewardType = type;
            this.qaSearch();
        },
        addisReward(isRewardId){
            this.isReward = isRewardId;
            this.qaSearch();
        }
    }
}
</script>
<style scoped>
.Fcti {
    width: 100%;
    height: 37px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #FF5E5E;
    border-bottom: none;
    color:#FF5E5E;
    font-size: 16px;
    box-sizing: border-box;
}
.hidden{
    font-weight: 600;
    font-size: 18px;
    margin: 20px 0 0 30px;
}
/deep/ .el-input__inner {
    border-radius: 0px;
}
</style>